<template>
  <v-card
    flat
    class="pa-0 mt-2"
    style="margin:0px; padding:0px"
  >
    <v-form
      ref="form"
      class="multi-col-validation mt-6"
    >
      <v-card-title
        class="flex-nowrap"
        style="margin-top:0px; padding-top:0px"
      >
        <v-icon class="text--primary me-3">
          {{ icon }}
        </v-icon>
        <span class="text-break">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            v-show="mode === 'update'"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.id"
              :label="id_field_label"
              :append-icon="icons.mdiContentCopy"
              dense
              outlined
              :disabled="false"
              :readonly="true"
              @click:append="idToClipboard"
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.label"
              hide-details="auto"
              label="Label"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="factorData.status"
              dense
              outlined
              label="Status *"
              :rules="requiredRule"
              hide-details="auto"
              :items="status"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="factorData.subtype"
              dense
              outlined
              label="Factor Type *"
              :disabled="mode === 'update'"
              :rules="requiredRule"
              hide-details="auto"
              :items="subtypes"
              @change="populateFactorTemplateValues"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="factorData.score"
              dense
              min="1"
              outlined
              :rules="scoreRule"
              hide-details="auto"
              type="number"
              label="Score *"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              v-model="factorData.config.unique"
              :disabled="mode === 'update'"
              dense
              outlined
              label="Unique"
              title="Whether the factor value must be unique across all accounts or multiple accounts can have the same factor value. A username for example is usually unique, while a password is not."
              @click.prevent="triggerFormValidation"
            ></v-checkbox>
          </v-col>

          <v-col
            v-show="factorData.config"
            cols="12"
            md="3"
          >
            <v-checkbox
              v-model="factorData.config.public_signup"
              dense
              outlined
              label="Public Signup"
              title="Whether this factor is allowed to be used to signup."
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              v-model="factorData.config['case_sensitive']"
              :disabled="mode === 'update'"
              dense
              outlined
              label="Case-sensitive"
              title="Whether the factor value is case-sensitive or not. If case-sensitive, then for example the values 'MyNickName' and 'mynickname' are not considered the same."
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title
        v-if="SHOW_REGEX"
        v-show="factorData.subtype && !factorData.subtype.startsWith('oauth2:')"
        class="flex-nowrap"
      >
        <span class="text-break">Policies</span>
      </v-card-title>

      <v-card-title
        v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:')"
        class="flex-nowrap"
      >
        <span class="text-break">OAuth2-specific Settings</span>
      </v-card-title>

      <v-card-text>
        <v-row
          v-if="SHOW_REGEX && factorData.config.regex"
          v-show="factorData.subtype && !factorData.subtype.startsWith('oauth2:')"
        >
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="factorData.config.regex"
              dense
              outlined
              clearable
              :disabled="mode === 'update' || (factorData.subtype && !factorData.subtype.startsWith('secret') && !factorData.subtype.startsWith('otp'))"
              :rules="regexRule"
              hide-details="auto"
              label="Input Regex"
              @keyup="triggerRefresh"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="mode === 'create'"
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="regex_test"
              dense
              outlined
              clearable
              hide-details="auto"
              label="Regular Expression Testfield"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              v-if="factorData.subtype==='secret:id' || factorData.subtype==='otp'"
              v-model="factorData.config.capture_input"
              dense
              outlined
              label="Capture Input"
              title="Whether to capture input in data event (for OTP this is bundled into the commmunication event)."
              @click.prevent="triggerFormValidation"
            ></v-checkbox>
          </v-col>

          <v-col
            v-if="regex_test_result !== undefined && mode === 'create'"
            cols="12"
            md="4"
            style="padding-top:20px;text-align:center"
          >
            <div
              :style="regex_test_result ? 'background-color:green' : 'background-color:red'"
            >
              {{ regex_test_result ? 'MEETING POLICY REQUIREMENTS' : 'NOT MEETING POLICY REQUIREMENTS' }}
            </div>
          </v-col>

          <v-col
            v-if="['secret:id', 'secret:password'].includes(factorData.subtype) && mode === 'create'"
            cols="12"
            md="12"
          >
            Examples for common regular expressions below. Make sure to avoid unsafe, catastrophic exponential-time regular expressions.<br /><br />
            <div v-if="factorData && factorData.subtype === 'secret:id'">
              Require string (any character) to be of certain minimum and maximum length<br />
              <strong class="code"><small>^.{1,100}$</small></strong><br /><br />
              Require alphanumeric characters (uppercase, lowercase, digit) with a fixed length of 43<br />
              <strong class="code"><small>[a-zA-Z0-9]{43}</small></strong><br /><br />
              Require a valid email address<br />
              <strong class="code"><small>^(([^&lt;&gt;()[ \]\\.,;:\s@&quot;]+(\.[^&lt;&gt;()[\]\\.,;:\s@&quot;]+)*)|(&quot;.+&quot;))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$</small></strong>
            </div>
            <div v-if="factorData && ['secret:password'].includes(factorData.subtype)">
              Require string (any character) to be of certain minimum and maximum length<br />
              <strong class="code"><small>^.{15,100}$</small></strong><br /><br />
              Require alphanumeric characters (uppercase, lowercase, digit) with a fixed length of 43<br />
              <strong class="code"><small>[a-zA-Z0-9]{43}</small></strong><br /><br />
            </div>
          </v-col>
        </v-row>

        <!-- OTP specific-->
        <v-row
          v-show="factorData.subtype==='otp' || factorData.subtype==='totp'"
        >
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="factorData.config.otp"
              dense
              outlined
              clearable
              :disabled="mode === 'update' || (factorData.subtype === 'totp')"
              :rules="regexRule"
              hide-details="auto"
              label="OTP Regex"
              @keyup="triggerRefresh"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- End: OTP specific-->

        <!-- OAuth2 specific-->
        <v-row
          v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:')"
        >
          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.config.issuer"
              :rules="requiredRule"
              hide-details="auto"
              label="Issuer"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.config.authorization_endpoint"
              :rules="requiredRule"
              hide-details="auto"
              label="Authorization Endpoint"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            :rules="requiredRule"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.config.token_endpoint"
              hide-details="auto"
              label="Token Endpoint"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.config.userinfo_endpoint"
              hide-details="auto"
              label="Userinfo Endpoint"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.config.jwks_uri"
              hide-details="auto"
              label="JWKS URI"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.config.scope"
              :rules="requiredRule"
              hide-details="auto"
              label="Scope"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            cols="12"
            md="6"
          >
            <v-select
              v-model="factorData.config.response_type"
              dense
              outlined
              clearable
              label="Response Type"
              hide-details="auto"
              :items="response_types"
            ></v-select>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            cols="12"
            md="6"
          >
            <v-select
              v-model="factorData.config.response_mode"
              dense
              outlined
              clearable
              label="Response Mode"
              hide-details="auto"
              :items="response_modes"
            ></v-select>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            cols="12"
            md="6"
          >
            <v-select
              v-model="factorData.config['content_type']"
              dense
              outlined
              clearable
              label="Content-Type (token endpoint)"
              hide-details="auto"
              :items="content_types"
            ></v-select>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            cols="12"
            md="6"
          >
            <v-select
              v-model="factorData.config['code_challenge_method']"
              dense
              outlined
              clearable
              label="Code Challenge Methods (PKCE)"
              hide-details="auto"
              :items="code_challenge_methods"
              item-text="label"
              item-value="id"
            ></v-select>
          </v-col>

          <v-col
            v-show="factorData.subtype && !factorData.subtype.startsWith('oauth2:quasr')"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.config.client_id"
              :rules="requiredRule"
              hide-details="auto"
              label="Client ID"
              clearable
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-select
              v-model="factorData.config.client_authentication"
              dense
              outlined
              label="Client Authentication *"
              hide-details="auto"
              :items="client_authentications"
              item-text="text"
              item-value="value"
              @change="validateForm"
            ></v-select>
          </v-col>

          <v-col
            v-show="(factorData.config.client_authentication && factorData.config.client_authentication.startsWith('client_secret')) || (factorData.subtype && !factorData.subtype.startsWith('oauth2:oidc') && !factorData.subtype.startsWith('oauth2:quasr'))"
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="factorData.config.client_secret"
              label="Client Secret"
              :rules="clientAuthFactorRule"
              :type="is_password_visible ? 'text' : 'password'"
              :append-icon="is_password_visible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              clearable
              dense
              outlined
              @click:append="is_password_visible = !is_password_visible"
            ></v-text-field>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-textarea
              v-if="factorData.config.client_keys"
              v-model="factorData.config.client_keys.private"
              outlined
              placeholder="-----BEGIN PRIVATE KEY-----"
              rows="3"
              label="Private Key (PEM-encoded PKCS8)"
            ></v-textarea>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-textarea
              v-if="factorData.config.client_keys"
              v-model="factorData.config.client_keys.public"
              outlined
              placeholder="-----BEGIN PUBLIC KEY-----"
              rows="3"
              label="Public Key (PEM-encoded SPKI)"
            ></v-textarea>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-checkbox
              v-model="factorData.config['nonce']"
              dense
              outlined
              label="Nonce"
              title="Whether the generate a nonce for the request."
            ></v-checkbox>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2:oidc')"
            md="6"
            cols="12"
          >
            <v-checkbox
              v-model="factorData.config['signed_request']"
              dense
              outlined
              label="Signed Request"
              title="Whether the add a signed request JWT to the request."
            ></v-checkbox>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2')"
            md="6"
            cols="12"
          >
            <v-checkbox
              v-model="factorData.config.capture_claims"
              dense
              outlined
              label="Capture Claims"
              title="Whether to capture claims in data event."
            ></v-checkbox>
          </v-col>

          <v-col
            v-show="factorData.subtype && factorData.subtype.startsWith('oauth2')"
            md="6"
            cols="12"
          >
            <v-checkbox
              v-model="factorData.config.capture_tokens"
              dense
              outlined
              label="Capture Tokens (Experimental)"
              title="Whether to capture tokens in data event."
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- End: OAuth2 specific-->
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>

// import gql from 'graphql-tag'
import * as safe from 'safe-regex'
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline,
  mdiEyeOutline, mdiFormSelect, mdiWavesArrowRight, mdiKey,
  mdiContentCopy,
} from '@mdi/js'

// import axios from 'axios'
import * as QuasrHelper from '@quasr-io/helper'
import { ref } from '@vue/composition-api'

// import store from '../../store'

export default {
  /**
   * props
   */
  props: {
    factorData: {
      type: Object,
      default: () => {},
    },
    type: { type: String, default: undefined },
    mode: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      regex_test: '',
      regex_test_result: undefined,
      regex_safe: undefined,
      oauth2FactorTemplates: {},
      requiredRule: [
        value => !!value || 'Required',
      ],
      regexRule: [
        value => !!value || 'Required',
      ],

      // avoid unsafe, catastrophic exponential-time regular expressions
      /*
      regexRule: [
        value => (!!value && safe(value)) || 'Required & RegEx must be safe',
      ],
      */
      positiveNumberRule: [
        value => (!!value && value >= 0) || 'Value must be >= 0',
      ],
      scoreRule: [
        value => (!!value && ((this.factorData.subtype !== 'secret:id' && value >= 1) || (this.factorData.subtype === 'secret:id' && value >= 0))) || (this.factorData.subtype === 'secret:id' ? 'Value must be >= 0' : 'Value must be >= 1'),
      ],
      clientAuthFactorRule: [
        value => (!!value || !this.factorData.config.client_authentication?.startsWith('client_secret')) || 'Required',
      ],
    }
  },

  /**
   * watch
   */
  watch: {
    regex_test(val) {
      if (this.regex_test) {
        this.regex_test_result = new RegExp(this.factorData.config.regex).test(val)
        this.regex_safe = safe(this.factorData.config.regex)
      } else {
        this.regex_test_result = undefined
        this.regex_safe = undefined
      }
    },
    factorData() {
      if (this.regex_test) {
        this.regex_test_result = new RegExp(this.factorData.config.regex).test(this.regex_test)
        this.regex_safe = safe(this.factorData.config.regex)
      } else {
        this.regex_test_result = undefined
        this.regex_safe = undefined
      }
    },
  },

  /**
   * methods
   */
  methods: {
    /**
     * validateForm
     */
    validateForm() {
      this.$refs.form.validate()
    },

    /**
     * populateFactorTemplateValues
     */
    populateFactorTemplateValues() {
      if (this.factorData.subtype) {
        if (this.factorData.subtype === 'secret:id') this.factorData.config.regex = '^.{1,100}$'
        if (this.factorData.subtype === 'secret:password') this.factorData.config.regex = '^.{15,100}$' // '[a-zA-Z0-9]{43}'
        if (this.factorData.subtype === 'otp') {
          this.factorData.config.regex = '[A-Z]{25}'
          this.factorData.config.otp = '[A-Z0-9]{6}'
        }
        if (this.factorData.subtype === 'totp') {
          this.factorData.config.regex = undefined
          this.factorData.config.otp = '[0-9]{6}'
        }
      } else {
        this.factorData.config.regex = undefined
      }
      this.validateForm()
    },

    /**
     * triggerFormValidation
     */
    triggerFormValidation() {
      this.$emit('trigger-form-validation')
    },

    /**
     * triggerRefresh
     */
    triggerRefresh() {
      this.$emit('trigger-refresh')
    },

    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * idToClipboard
     */
    idToClipboard() {
      QuasrHelper.copyTextToClipboard(this.factorData.id)
    },
  },
  setup(props) {
    const SHOW_REGEX = true
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const is_password_visible = ref(false)
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const title = props.type === 'enrollment' ? 'Enrollment Settings' : 'Factor Settings'
    const id_field_label = (props.type === 'enrollment' ? 'Enrollment ID' : 'Factor ID').concat(' (read-only)')
    const icon = mdiKey
    const is_currentPassword_visible = ref(false)
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]
    const subtypes = [
      { text: 'Apple', value: 'oauth2:apple' },
      { text: 'Authenticator App (Time-Based One-Time Password)', value: 'totp' },
      { text: 'Facebook', value: 'oauth2:facebook' },
      { text: 'Github', value: 'oauth2:github' },
      { text: 'Google', value: 'oauth2:google' },
      { text: 'LinkedIn', value: 'oauth2:linkedin' },
      { text: 'Microsoft', value: 'oauth2:microsoft' },
      { text: 'Slack', value: 'oauth2:slack' },
      { text: 'Discord', value: 'oauth2:discord' },
      { text: 'One-Time Password', value: 'otp' },
      { text: 'OpenID Connect (Generic)', value: 'oauth2:oidc' },
      { text: 'Password', value: 'secret:password' },
      { text: 'Quasr', value: 'oauth2:quasr' },
      { text: 'Username', value: 'secret:id' },
      { text: 'Private Key', value: 'jwt:spki' },
      { text: 'Hosted Key Set', value: 'jwt:jwks' },
      { text: 'Personal Token', value: 'jwt:bearer' },
    ]

    /*
    if (props.mode === 'update') {
      subtypes.push({ text: 'Secret', value: 'secret' })
    }
    */
    const response_modes = ['query', 'form_post']
    const response_types = ['code', 'id_token']
    const content_types = ['application/json', 'application/x-www-form-urlencoded']
    const code_challenge_methods = [
      { id: 'NONE', label: 'none (don\'t use PKCE)' },
      { id: 'S256', label: 'S256' },
      { id: 'plain', label: 'plain' },
    ]
    const client_authentications = [
      { text: 'None', value: 'none' },
      { text: 'Client Secret (Basic)', value: 'client_secret_basic' },
      { text: 'Client Secret (Post)', value: 'client_secret_post' },
      { text: 'Private Key JWT', value: 'private_key_jwt' },
    ]

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      SHOW_REGEX,
      response_modes,
      response_types,
      content_types,
      code_challenge_methods,
      client_authentications,
      is_password_visible,
      loginStatus,
      title,
      id_field_label,
      icon,
      is_currentPassword_visible,
      status,
      subtypes,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiWavesArrowRight,
        mdiKey,
        mdiContentCopy,
      },
    }
  },
}
</script>
