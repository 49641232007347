<template>
  <v-card id="factor-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="(tab, index) in tabs"
        v-show="index === 0 || (index === 1 && type === 'factor' && factorData.subtype && factorData.subtype.startsWith('oauth2')) || (false && index === 2 && type === 'factor' && mode === 'update')"
        :key="tab.id"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-col
      cols="12"
      style="padding-bottom:0px; margin-bottom:0px"
    >
      <v-alert
        v-show="alert.show"
        :color="alert.color"
        text
        class="mb-0"
      >
        <div class="d-flex align-start">
          <v-icon :color="alert.color">
            {{ icons.mdiAlertOutline }}
          </v-icon>

          <div class="ms-3">
            <p class="text-base font-weight-medium mb-1 justify-right">
              {{ alert.message }}
            </p>
            <a
              align="right"
              href="javascript:void(0)"
              title="Hide this message"
              :class="alert.class"
              @click.prevent="hideAlert"
            >
              <span class="text-sm">{{ alert.callToAction }}</span>
            </a>
          </div>
        </div>
      </v-alert>
    </v-col>

    <!-- loading icon -->
    <div
      v-show="isLoading"
      id="misc"
    >
      <div class="page-title text-center justify-center px-5">
        <div class="misc-character d-flex justify-center">
          <v-img
            max-width="100"
            margin="100"
            src="@/assets/images/misc/loading-spinner-dark.gif"
          ></v-img>
        </div>
      </div>
    </div>

    <!-- tabs item -->
    <v-tabs-items
      v-show="!isLoading"
      v-model="tab"
    >
      <v-tab-item>
        <factor-settings-general
          :factor-data="factorData"
          :type="type"
          :mode="mode"
          @trigger-form-validation="validateForm()"
          @trigger-refresh="refresh()"
        ></factor-settings-general>
      </v-tab-item>
    </v-tabs-items>

    <!-- <v-divider></v-divider> -->
    <v-card
      flat
      class="pa-0 mt-2"
    >
      <v-card-text>
        <v-form class="multi-col-validation mt-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                :disabled="!isFormValid || isLoading"
                @click="saveFactor"
              >
                Save changes
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                :disabled="isLoading"
                @click.prevent="cancel"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              v-if="mode === 'update'"
              cols="6"
              class="text-right"
            >
              <v-btn
                color="error"
                outlined
                class="me-3 mt-4"
                type="reset"
                :disabled="!factorData.id || isLoading"
                @click="deleteItem"
              >
                Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to delete this item?
        </v-card-title>
        <!--
        <v-card-text v-if="factorData && ['id', 'secret'].includes(factorData.subtype) && factorData.config && factorData.config.seeded">
          If you delete this factor, you won't be able to use CLIENT_SECRET as an authentication method with clients anymore.
        </v-card-text>
        -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiRobotOutline,
  mdiFingerprint, mdiWavesArrowRight, mdiAutoFix, mdiFormSelect, mdiBadgeAccountOutline,
  mdiBadgeAccountAlertOutline, mdiProtocol, mdiLockOutline, mdiKeyChain, mdiKey,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'
import * as QuasrTypes from '@quasr-io/types'
import { ref } from '@vue/composition-api'
import gql from 'graphql-tag'
import { getFactor } from '../../graphql/queries'
import { deleteFactor, updateFactor, createFactor } from '../../graphql/mutations'
import FactorSettingsGeneral from './FactorSettingsGeneral.vue'
import store from '../../store'

export default {

  /**
   * components
   */
  components: {
    FactorSettingsGeneral,
  },

  /**
   * props
   */
  props: {
    type: { type: String, default: 'factor' },
  },

  /**
   * data
   */
  data() {
    return {
      mode: this.$route.params.id === 'new' ? 'create' : 'update',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      dialog: false,
      dialogDelete: false,
      factorData: { config: {}, score: 0 },
    }
  },

  /**
   * computed
   */
  computed: {

    /**
     * isFormValid
     */
    isFormValid() {
      const missingFields = []
      if (this.mode === 'update' && !this.factorData.id) {
        missingFields.push('ID')
      }
      if (!this.factorData.status) {
        missingFields.push('Status')
      }
      if (this.factorData.score === null || this.factorData.score === undefined || ((this.factorData.subtype !== 'secret:id' && this.factorData.score < 1) || (this.factorData.subtype === 'secret:id' && this.factorData.score < 0))) {
        missingFields.push('Score')
      }
      if (this.factorData.subtype && this.factorData.subtype.startsWith(QuasrTypes.Subtype.OAUTH2) && !this.factorData.config.client_id) {
        if (this.factorData.subtype !== 'oauth2:quasr') missingFields.push('Client ID')
      }
      if (['secret:id', 'secret:password'].includes(this.factorData.subtype) && !this.factorData?.config?.regex) {
        missingFields.push('Regex')
      }
      QuasrHelper.log('Missing fields: '.concat(JSON.stringify(missingFields)))

      /*
      if (this.factorData.config.regex) {
        RegExp(this.factorData.config.regex).test('ads')
      }
      */

      return missingFields.length === 0
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    await this.getFactor()
    this.isLoading = false
  },

  /**
   * methods
   */
  methods: {
    /**
     * getFactor
     */
    async getFactor() {
      this.isLoading = true
      if (this.mode === 'create') {
        this.factorData = {
          status: 'ENABLED',
          config: {
            client_keys: {},
          },
        }
      } else {
        try {
          const factor = await store.getAppSyncClient().query({ query: gql(getFactor), variables: { id: this.$route.params.id } })
          if (!factor.data.getFactor.config) factor.data.getFactor.config = {}
          this.factorData = factor.data.getFactor
        } catch (err) {
          QuasrHelper.log(err)
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.cancel()
          }
        }
      }
      this.isLoading = false
    },

    /**
     * refresh
     */
    refresh() {
      this.factorData = JSON.parse(JSON.stringify(this.factorData))
    },

    /**
     * validateForm
     */
    validateForm() {
      const missingFields = []
      if (this.mode === 'update' && !this.factorData.id) {
        missingFields.push('ID')
      }
      if (!this.factorData.label) {
        missingFields.push('Label')
      }
      QuasrHelper.log('Missing fields: '.concat(JSON.stringify(missingFields)))

      return missingFields.length === 0
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.dialogDelete = false
    },

    /**
     * deleteItem
     */
    deleteItem() {
      this.dialogDelete = true
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      await this.deleteFactor(this.factorData.id)
      store.message = { text: 'Factor deleted successfully.', action: 'OK', color: 'success' }
      this.closeDelete()
    },

    /**
     * deleteFactor
     */
    async deleteFactor() {
      await store.getAppSyncClient().mutate({ mutation: gql(deleteFactor), variables: { input: { id: this.factorData.id } } })
      this.$router.back()
    },

    /**
     * saveFactor
     */
    async saveFactor() {
      this.isLoading = true
      const obj = {
        label: this.factorData.label,
        status: this.factorData.status,
        subtype: this.factorData.subtype,
        score: parseInt(this.factorData.score, 10),
        config: {
          public_signup: this.factorData.config.public_signup,
        },
      }
      obj.config.public_signup = this.factorData.config.public_signup
      if (this.factorData.subtype && this.factorData.subtype.startsWith('secret')) {
        obj.config.regex = this.factorData.config.regex
      }
      if (this.factorData.subtype && (this.factorData.subtype === 'secret:id' || this.factorData.subtype === 'otp')) {
        obj.config.capture_input = this.factorData.config.capture_input
      }
      if (this.factorData.subtype && this.factorData.subtype === 'otp') {
        obj.config.regex = this.factorData.config.regex
        obj.config.otp = this.factorData.config.otp
      }
      if (this.factorData.subtype && this.factorData.subtype.startsWith('oauth2:')) {
        obj.config.client_id = this.factorData.config.client_id
        obj.config.client_secret = this.factorData.config.client_secret
        if (this.factorData.subtype.startsWith('oauth2:oidc')) {
          obj.config.content_type = this.factorData.config.content_type
          obj.config.authorization_endpoint = this.factorData.config.authorization_endpoint
          obj.config.token_endpoint = this.factorData.config.token_endpoint
          obj.config.userinfo_endpoint = this.factorData.config.userinfo_endpoint
          obj.config.jwks_uri = this.factorData.config.jwks_uri
          obj.config.issuer = this.factorData.config.issuer
          obj.config.scope = this.factorData.config.scope
          obj.config.response_type = this.factorData.config.response_type
          obj.config.response_mode = this.factorData.config.response_mode
          obj.config.code_challenge_method = this.factorData.config.code_challenge_method
          obj.config.client_authentication = this.factorData.config.client_authentication
          obj.config.nonce = this.factorData.config.nonce
          obj.config.signed_request = this.factorData.config.signed_request
          if (this.factorData.config.client_keys.private) {
            obj.config.client_keys = {
              public: this.factorData.config.client_keys.public,
              private: this.factorData.config.client_keys.private,
            }
          }
        }
        obj.config.capture_claims = this.factorData.config.capture_claims
        obj.config.capture_tokens = this.factorData.config.capture_tokens
      }
      if (this.mode === 'create') {
        /*
        if (this.factorData.subtype && ['id', 'password', 'secret'].includes(this.factorData.subtype)) {
          obj.config.regex = this.factorData.config.regex
        }
        */
        obj.subtype = this.factorData.subtype
        obj.config.unique = this.factorData.config.unique
        obj.config.public_signup = this.factorData.config.public_signup
        if (!obj.config.public_signup) obj.config.public_signup = false
        obj.config.case_sensitive = this.factorData.config.case_sensitive
        if (!obj.config.case_sensitive) obj.config.case_sensitive = false

        try {
          const cmd = await store.getAppSyncClient().mutate({ mutation: gql(createFactor), variables: { input: obj } })
          if (cmd.data.createFactor) {
            this.factorData = cmd.data.createFactor
            this.mode = 'update'

            // this.showAlert('Factor saved successfully.', 'OK', 'success')
            store.message = { text: 'Factor saved successfully.', action: 'OK', color: 'success' }
            this.cancel()
          } else {
            this.showAlert('Saving factor failed.', 'OK', 'error')
            this.isLoading = false
          }
        } catch (err) {
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.showAlert(`Saving account failed. ${err}`, 'OK', 'error')
            this.isLoading = false
          }
        }
      } else if (this.mode === 'update') {
        obj.id = this.factorData.id
        obj.id = this.factorData.id
        try {
          const cmd = await store.getAppSyncClient().mutate({ mutation: gql(updateFactor), variables: { input: obj } })
          if (cmd.data.updateFactor) {
            // this.showAlert('Factor saved successfully.', 'OK', 'success')
            store.message = { text: 'Factor saved successfully.', action: 'OK', color: 'success' }
            this.cancel()
          } else {
            this.showAlert('Saving factor failed.', 'OK', 'error')
            this.isLoading = false
          }
        } catch (err) {
          const e = JSON.parse(JSON.stringify(err))
          if (e.status === 401 || e.networkError?.statusCode === 401) {
            localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
            this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
          } else {
            this.showAlert(`Saving account failed. ${err}`, 'OK', 'error')
            this.isLoading = false
          }
        }
      }
    },
  },

  /**
   * setup
   */
  setup(props) {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const tab = ref('')
    const isLoading = true
    const tabs = [
      {
        id: 0, title: props.type === 'enrollment' ? 'Enrollment Settings' : 'Factor Settings', icon: mdiKey, show: true,
      },
    ]

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      isLoading,
      loginStatus,
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiRobotOutline,
        mdiFingerprint,
        mdiBadgeAccountAlertOutline,
        mdiBadgeAccountOutline,
        mdiWavesArrowRight,
        mdiAutoFix,
        mdiFormSelect,
        mdiProtocol,
        mdiLockOutline,
        mdiKeyChain,
        mdiKey,
      },
    }
  },
}
</script>
